import './Table.scss';
import {useDispatch, useSelector} from 'react-redux';
import {deleteVictim, fetchVictims, setPage} from '../../redux/victimsSlice';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import * as React from 'react'

function Table(props, ref) {
  const {filter} = props;
  const authToken = useSelector((state) => state.auth.auth_token);
  const data      = useSelector((state) => state.victims.victims);
  const totalRecords = useSelector((state) => state.victims.totalRecords);
  const isLoading = useSelector((state => state.victims.isLoading));
  const page      = useSelector(state => state.victims.page);
  const lastPage  = useSelector(state => state.victims.lastPage);
  const dispatch  = useDispatch();

  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => {
    dispatch(fetchVictims({page, filter}))
    collapseAllRows();
  }, [dispatch, page, filter]);

  useImperativeHandle(ref, () => ({ collapseAllRows }));

  function deleteAttempt(victimId) {
    if (window.confirm('Da li ste sigurni da želite da obrišete ovaj red?')) {
      dispatch(deleteVictim({victimId, page}));
    }
  }

  function expandRow(index) {
    const newState = {...expandedRows};
    newState[index] = !newState[index];
    setExpandedRows(newState);
  }

  function collapseAllRows() {
    setExpandedRows({});
  }

  return (
    <div>
      <table>
        <thead>
        <tr>
          <th/>
          <th>Ime</th>
          <th>Prezime</th>
          <th>Roditelj</th>
          <th>Godina rođenja</th>
          {/*<th>Општина рођења</th>*/}
          <th>Opština boravka 6. aprila 1941</th>
          <th>Mesto rođenja</th>
          <th>Nacionalnost</th>
          {authToken && <th>Akcije</th>}
        </tr>
        </thead>
        {isLoading ? <tbody>
          <tr>
            <td colSpan={13}>Učitavanje podataka ...</td>
          </tr>
          </tbody>
          : <tbody>
          {
            data.map((row, i) =>
              <React.Fragment key={i}>
                <tr key={i} className='topRow' onClick={() => expandRow(i)}>
                  <td>{expandedRows[i] ? '-' : '+'}</td>
                  <td>{row.first_name}</td>
                  <td>{row.last_name}</td>
                  <td>{row.parent}</td>
                  <td>{row.birth_year}.</td>
                  {/*<td>{row.birth_place}</td> */}
                  <td>{row.stay_county}</td>
                  <td>{row.birth_place}</td>
                  <td>{row.nationality}</td>

                  {authToken && <td rowSpan={2} style={{backgroundColor: '#fff'}}>
                    {/*<div><Link to={`/edit/${row.id}`}>Izmeni</Link></div>*/}
                    <span onClick={() => deleteAttempt(row.id)}><a href="#">Obriši</a></span>
                  </td>}
                </tr>
                <tr className={'detailsRow ' + (expandedRows[i] ? 'visible' : 'hidden')}>
                    <td colSpan='8'>
                      <div className='detailsGroupWrapper' style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <div>
                          <div className='detailsGroup'>
                            {/*<div className='detailsLabel'>Општина боравка 6. априла 1941:</div>*/}
                            <div className='detailsLabel'>Opština rođenja:</div>
                            {/*<div className='detailsInfo'>{row.stay_place}</div>*/}
                            <div className='detailsInfo'>{row.birth_county}</div>
                          </div>
                          <div className='detailsGroup'>
                            <div className='detailsLabel'>Mesto boravka 6. aprila 1941:</div>
                            <div className='detailsInfo'>{row.stay_place}</div>
                          </div>
                          <div className='detailsGroup'>
                            <div className='detailsLabel'>Godina stradanja:</div>
                            <div className='detailsInfo'>{row.death_year}.</div>
                          </div>
                          <div className='detailsGroup'>
                            <div className='detailsLabel'>Mesto stradanja:</div>
                            <div className='detailsInfo'>{row.death_place}</div>
                          </div>
                        </div>

                        <div>
                          <div className='detailsGroup'>
                            <div className='detailsLabel'>Okolnost stradanja:</div>
                            <div className='detailsInfo'>{row.death_info}</div>
                          </div>
                          <div className='detailsGroup'>
                            <div className='detailsLabel'>Učinilac zločina:</div>
                            <div className='detailsInfo'>{row.committer}</div>
                          </div>
                          <div className='detailsGroup'>
                            <div className='detailsLabel'>Identifikacioni broj:</div>
                            <div className='detailsInfo'>{row.info}</div>
                          </div>
                        </div>
                      </div>
                    </td>
                </tr>
              </React.Fragment>
            )
          }
          </tbody>
        }
      </table>
      <div style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}>
        <div style={{textAlign: 'left', padding: '0 10px'}}>
          <div>Trenutna stranica: {page}</div>
          <div>Ukupno rezultata: {totalRecords}</div>
        </div>
        <div style={{padding: '0 10px'}}>
          {page > 1 &&
          <button onClick={() => {
            collapseAllRows();
            dispatch(setPage(page - 1));
          }}>Predhodna stranica</button>
          }
          {page < lastPage &&
          <button onClick={() => {
            collapseAllRows();
            dispatch(setPage(page + 1));
          }}>Sledeća stranica</button>
          }
        </div>
      </div>
    </div>
  )
}


export default forwardRef(Table);
