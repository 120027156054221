import './Filter.scss';
import {useState} from 'react';
import {fetchVictims, setPage} from '../../redux/victimsSlice';
import {useDispatch, useSelector} from 'react-redux';

function latToCir(string) {
  let Lat2Cyr = {
    "a": "а",
    "b": "б",
    "c": "ц",
    "d": "д",
    "e": "е",
    "f": "ф",
    "g": "г",
    "h": "х",
    "i": "и",
    "j": "ј",
    "k": "к",
    "l": "л",
    "m": "м",
    "n": "н",
    "o": "о",
    "p": "п",
    "q": "",
    "r": "р",
    "s": "с",
    "t": "т",
    "u": "у",
    "v": "в",
    "w": "",
    "x": "",
    "y": "",
    "z": "з",

    "A": "А",
    "B": "Б",
    "C": "Ц",
    "D": "Д",
    "E": "Е",
    "F": "Ф",
    "G": "Г",
    "H": "Х",
    "I": "И",
    "J": "Ј",
    "K": "К",
    "L": "Л",
    "M": "М",
    "N": "Н",
    "O": "О",
    "P": "П",
    "Q": "",
    "R": "Р",
    "S": "С",
    "T": "Т",
    "U": "У",
    "V": "В",
    "W": "",
    "X": "",
    "Y": "",
    "Z": "З",

    "č": "ч",
    "ć": "ћ",
    "đ": "ђ",
    "ž": "ж",
    "š": "ш",

    "Č": "Ч",
    "Ć": "Ћ",
    "Đ": "Ђ",
    "Ž": "Ж",
    "Š": "Ш"
  };
  let res = '';

  for (let i = 0; i < string.length; i++) {
    const char = string.charAt(i);
    res += Lat2Cyr[char] || char;
  }
  return res;
}

function Filter(props) {
  const {submitFn} = props;
  const dispatch = useDispatch();
  const page     = useSelector(state => state.victims.page);

  const [lastName, setLastName]     = useState('');
  const [firstName, setFirstName]   = useState('');
  const [birthYear, setBirthYear]   = useState('');
  const [stayPlace, setStayPlace]   = useState('');
  const [stayCounty, setStayCounty] = useState('');
  const [deathPlace, setDeathPlace] = useState('');
  const [deathYear, setDeathYear] = useState('');
  const [deathInfo, setDeathInfo] = useState('');

  const filter = {
    last_name: lastName.toLowerCase(),
    first_name: firstName.toLowerCase(),
    birth_year: birthYear.toLowerCase(),
    stay_place: stayPlace.toLowerCase(),
    stay_county: stayCounty.toLowerCase(),
    death_place: deathPlace.toLowerCase(),
    death_year: deathYear.toLowerCase(),
    death_info: deathInfo.toLowerCase(),

    // last_name: latToCir(lastName).toLowerCase(),
    // first_name: latToCir(firstName).toLowerCase(),
    // birth_year: latToCir(birthYear).toLowerCase(),
    // stay_place: latToCir(stayPlace).toLowerCase(),
    // stay_county: latToCir(stayCounty).toLowerCase(),
    // death_place: latToCir(deathPlace).toLowerCase(),
  };

  function submit() {
    // dispatch(fetchVictims({page, filter}))
    dispatch(setPage(1));
    submitFn(filter);
  }

  function clearFields() {
    setLastName('');
    setFirstName('');
    setBirthYear('');
    setStayPlace('');
    setStayCounty('');
    setDeathPlace('');
    setDeathYear('');
    setDeathInfo('');

    submitFn('clear');
    dispatch(setPage(1));
  }


  return (
    <div className="filters">
      <div>
        <div className="column">
          <div className='formGroup'>
            <div>
              <label htmlFor="">Ime</label>
            </div>
            <div>
              <input type="text" value={firstName} onChange={({target}) => setFirstName(target.value)}/>
            </div>
          </div>

          <div className='formGroup'>
            <div>
              <label htmlFor="">Godina rođenja</label>
            </div>
            <div>
              <input type="text" value={birthYear} onChange={({target}) => setBirthYear(target.value)}/>
            </div>
          </div>

          <div className='formGroup'>
            <div>
              <label htmlFor="">Opština boravka pre rata</label>
            </div>
            <div>
              <input type="text" value={stayCounty} onChange={({target}) => setStayCounty(target.value)}/>
            </div>
          </div>

          <div className='formGroup'>
            <div>
              <label htmlFor="">Mesto boravka pre rata</label>
            </div>
            <div>
              <input type="text" value={stayPlace} onChange={({target}) => setStayPlace(target.value)}/>
            </div>
          </div>
        </div>

        <div className="column">
          <div className='formGroup'>
            <div>
              <label htmlFor="">Prezime</label>
            </div>
            <div>
              <input type="text" value={lastName} onChange={({target}) => setLastName(target.value)}/>
            </div>
          </div>

          <div className='formGroup'>
            <div>
              <label htmlFor="">Mesto stradanja</label>
            </div>
            <div>
              <input type="text" value={deathPlace} onChange={({target}) => setDeathPlace(target.value)}/>
            </div>
          </div>

          <div className='formGroup'>
            <div>
              <label htmlFor="">Godina stradanja</label>
            </div>
            <div>
              <input type="text" value={deathYear} onChange={({target}) => setDeathYear(target.value)}/>
            </div>
          </div>

          <div className='formGroup'>
            <div>
              <label htmlFor="">Okolnosti stradanja</label>
            </div>
            <div>
              <input type="text" value={deathInfo} onChange={({target}) => setDeathInfo(target.value)}/>
            </div>
          </div>
        </div>
      </div>

      <div style={{justifyContent: "center", marginTop: '20px'}}>
        <div className='formGroup'>
          <div/>
          <div className='buttons'>
            <button onClick={() => submit()}>Pretraži</button>
            <button onClick={() => clearFields()}>Nova pretraga</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Filter;
