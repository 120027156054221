import { configureStore } from '@reduxjs/toolkit'
import authReducer from './redux/authSlice';
import victimsReducer from './redux/victimsSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    victims: victimsReducer
  },
})
