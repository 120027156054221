import './Login.scss';
import {useState} from 'react';
import HttpClient from '../../components/HttpClient';
import {useDispatch} from 'react-redux';
import {setUser} from '../../redux/authSlice';
import {useNavigate} from 'react-router-dom';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate  = useNavigate();

  const submit = () => {
    HttpClient.login(username, password)
      .then(res => {
        const {data} = res;

        if (data.jwt) {
          localStorage.setItem('auth_token', data.jwt);
          dispatch(setUser(data.jwt));
          navigate('/');
        }
      })
      .catch(err => {
        console.error(err);
      })
  }

  return (
    <div className='loginDiv'>
      <div>Prijava</div>

      <div>
        <div>Korisnicko ime</div>
        <div>
          <input type="text" value={username} onChange={(event) => setUsername(event.target.value)}/>
        </div>
      </div>

      <div>
        <div>Lozinka</div>
        <div>
          <input type="password" value={password} onChange={(event => setPassword(event.target.value))}/>
        </div>
      </div>

      <div>
        <button onClick={submit}>Prijavi se</button>
      </div>
    </div>
  )
}

export default Login;
