import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../components/HttpClient';

export const fetchVictims = createAsyncThunk(
  'victims/fetchAll',
  async ({page, filter}) => {
    const res = await HttpClient.fetchAll(page, filter);
    return res.data;
  }
)

export const deleteVictim = createAsyncThunk(
  'victims/delete',
  async ({victimId, page}) => {
    const res = await HttpClient.delete(victimId, page);
    return res.data;
  }
)

export const victimsSlice = createSlice({
  name: 'victims',
  initialState: {
    page: 1,
    isLoading: false,
    victims: [],
    filter: {}
  },
  reducers: {
    setPage: (state, {payload}) => {
      state.page = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchVictims.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchVictims.fulfilled, (state, {payload}) => {
        state.isLoading = false;
        state.victims   = payload.data;
        state.totalRecords  = payload.totalRecords;
        state.lastPage   = payload.pages;
      })
      .addCase(fetchVictims.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteVictim.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteVictim.fulfilled, (state, {payload}) => {
        state.isLoading = false;
        state.victims   = payload.data;
      })
      .addCase(deleteVictim.rejected, (state, action) => {
        state.isLoading = false;
      })
  }
});

export const {setPage} = victimsSlice.actions;
export default victimsSlice.reducer;

