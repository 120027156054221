import './Editor.scss';
import {useState} from 'react';
import HttpClient from '../../components/HttpClient';
import {useNavigate} from 'react-router-dom';

function Editor({editMode}) {
  const [lastName, setLastName]       = useState('');
  const [firstName, setFirstName]     = useState('');
  const [parent, setParent]           = useState('');
  const [nationality, setNationality] = useState('');
  const [birthYear, setBirthYear]     = useState('');
  const [birthPlace, setBirthPlace]   = useState('');
  const [birthCounty, setBirthCounty] = useState('');
  const [stayPlace, setStayPlace]     = useState('');
  const [stayCounty, setStayCounty]   = useState('');
  const [deathYear, setDeathYear]     = useState('');
  const [deathPlace, setDeathPlace]   = useState('');
  const [deathInfo, setDeathInfo]     = useState('');
  const [committer, setCommitter]     = useState('');
  const [info, setInfo]               = useState('');
  const [infoMessage, setInfoMessage] = useState({type: null, message: ''})

  const navigate = useNavigate();


  const submit = () => {
    HttpClient.insert({
      lastName,
      firstName,
      parent,
      nationality,
      birthYear,
      birthPlace,
      birthCounty,
      stayPlace,
      stayCounty,
      deathYear,
      deathPlace,
      deathInfo,
      committer,
      info
    })
      .then(res => {
        const {data} = res;
        if (data?.success) {
          setInfoMessage({type: 'success', message: "Uspešno dodat novi zapis"});
          setLastName('');
          setFirstName('');
          setParent('');
          setNationality('');
          setBirthYear('');
          setBirthPlace('');
          setBirthCounty('');
          setStayPlace('');
          setStayCounty('');
          setDeathYear('');
          setDeathPlace('');
          setDeathInfo('');
          setCommitter('');
          setInfo('');
        }
        else {
          setInfoMessage({type: 'error', message: "Došlo je do greške"});
        }
      })
      .catch(err => {
        console.error(err);
      })
  }

  return (
    <div className='editorWrapper'>
      <div>{editMode ? 'Izmena zapisa' : 'Dodavanje novog zapisa'}</div>

      {infoMessage.type &&
      <div className={infoMessage.type}>{infoMessage.message}</div>
      }

      <div className='formGroup'>
        <label>Име</label>
        <div>
          <input type="text" value={firstName} onChange={({target}) => setFirstName(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Презиме</label>
        <div>
          <input type="text" value={lastName} onChange={({target}) => setLastName(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Родитељ</label>
        <div>
          <input type="text" value={parent} onChange={({target}) => setParent(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Националност</label>
        <div>
          <input type="text" value={nationality} onChange={({target}) => setNationality(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Година рођења</label>
        <div>
          <input type="text" value={birthYear} onChange={({target}) => setBirthYear(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Општина рођења</label>
        <div>
          <input type="text" value={birthPlace} onChange={({target}) => setBirthPlace(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Место рођења</label>
        <div>
          <input type="text" value={birthCounty} onChange={({target}) => setBirthCounty(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Место боравка 6. априла 1941.</label>
        <div>
          <input type="text" value={stayPlace} onChange={({target}) => setStayPlace(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Општина боравка 6. априла 1941.</label>
        <div>
          <input type="text" value={stayCounty} onChange={({target}) => setStayCounty(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Година страдања</label>
        <div>
          <input type="text" value={deathYear} onChange={({target}) => setDeathYear(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Место страдања</label>
        <div>
          <input type="text" value={deathPlace} onChange={({target}) => setDeathPlace(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Околност страдања</label>
        <div>
          <input type="text" value={deathInfo} onChange={({target}) => setDeathInfo(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Учинилац злочина</label>
        <div>
          <input type="text" value={committer} onChange={({target}) => setCommitter(target.value)}/>
        </div>
      </div>

      <div className='formGroup'>
        <label>Напомена</label>
        <div>
          <input type="text" value={info} onChange={({target}) => setInfo(target.value)}/>
        </div>
      </div>

      <div>
        {editMode &&
        <button onClick={() => navigate(-1)}>Повратак</button>
        }
        <button onClick={submit}>{editMode ? 'Измени' : 'Додај'}</button>
      </div>
    </div>
  )
}

export default Editor;
