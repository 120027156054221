import Filter from '../../components/Filter/Filter';
import Table from '../../components/Table/Table';
import {useRef, useState} from 'react';


function Home() {
  const tableRef = useRef(null);
  const [filter, setFilter] = useState({})

  const filterSubmit = (filter) => {
    if (filter === 'clear') {
      setFilter({});
      return;
    }

    setFilter(filter);
    if (tableRef.current) {
      tableRef.current.collapseAllRows();
    }
  }

  return (
    <div>
      <Filter submitFn={filterSubmit}/>
      <Table filter={filter} ref={tableRef}/>
    </div>
  )
}

export default Home;
