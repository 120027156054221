import axios from 'axios';
import {useDispatch} from 'react-redux';
import {removeUser} from '../redux/authSlice';

const headers = {
  'Content-Type': 'application/json'
};

class HttpClient {
  constructor() {
    this.client = axios.create({
      baseURL: 'https://baza.muzejgenocida.rs/api',
      headers
    });

    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem('auth_token');
          window.location.href = '/login';
        }
        return Promise.reject(err);
      }
    );
  }

  setAuthToken(token) {
    this.client.interceptors.request.use(config => {
      config.headers.Authorization = token;
      return config;
    });
  }

  login(username, password) {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    return this.client.post(`?a=login`, formData);
  }

  fetchAll(page = 1, filter = {}) {
    const formData = new FormData();
    formData.append('page', page);

    for (let key in filter) {
      if (filter[key] && filter[key] !== '') {
        formData.append(key, filter[key])
      }
    }

    return this.client.post(`?a=getAll`, formData)
  }

  insert(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    return this.client.post(`?a=insert`, formData);
  }

  delete(id, page) {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('page', page);
    return this.client.post(`?a=delete`, formData)
  }
}


export default new HttpClient();
