import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {},
  reducers: {
    setUser: (state, action) => {
      state.auth_token = action.payload;
    },
    removeUser: (state) => state.auth_token = undefined,
  }
});


export const {setUser, removeUser} = authSlice.actions;

export default authSlice.reducer;
