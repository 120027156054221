import './App.scss';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setUser, removeUser} from './redux/authSlice';
import Editor from './pages/Editor/Editor';
import HttpClient from './components/HttpClient';

function App() {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.auth_token);

  useEffect( () => {
    const jwt = localStorage.getItem('auth_token');
    if (jwt) {
      dispatch(setUser(jwt));
    }
  }, [dispatch]);


  useEffect(() => {
    if (authToken) {
      HttpClient.setAuthToken(authToken);
    }
  }, [authToken])

  return (
    <div className="App">
      <BrowserRouter>
        <div>
          { authToken &&
            <nav>
              <ul>
                <li>
                  <Link to="/login" onClick={() => {
                    dispatch(removeUser());
                    localStorage.clear();
                  }}>Odjava</Link>
                </li>
                <li>
                  <Link to="/insert">Dodaj</Link>
                </li>
                <li>
                  <Link to="/">Početna</Link>
                </li>
              </ul>
            </nav>
          }
          <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/insert" element={<Editor  editMode={false}/>}/>
            <Route path="/edit/:recordId" element={<Editor editMode={true}/>}/>
            <Route path="/" element={<Home/>}/>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}


export default App;
